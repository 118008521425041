

































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import TariffsTagsHelper from '../../../mixins/TariffsTagsHelper'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CenteredColumnLayout,
    PageTitle
  },
})
export default class ReputationExtendedXp extends Mixins(TariffsTagsHelper) {
  pageHelpMessageIsOpen = false
}
