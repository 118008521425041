var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.getTagsByFieldKey("extended_reputation"))?_c('a-card',[_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': this,
        'key': 'enabled',
        'prefix': 'xp_',
        'hasAnchor': true,
      }
    }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.enabled)?_c('nested-content',[_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': this,
            'key': 'xp_action_jack_pot_chance_computed',
            'max': 100,
            'step': 0.1,
            'hasAnchor': true,
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'xp_action_jack_pot_multiply',
            'min': 1,
            'max': 100,
            'hasAnchor': true,
          }
        }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'xp_action_jack_pot_message',
            'placeholders': _vm.XP_PLACEHOLDERS,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
          },
        }}}),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('xp_action_reward_title'),"mini-help-message":_vm.$t('xp_action_reward_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'xp_min_message_len',
                'hasAnchor': true,
              }
            }}}),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('reward_write_message_title'),"mini-help-message":_vm.$t('reward_write_message_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteMessage,
                    'key': 'min',
                    'max': _vm.xp_action_reward.RewardWriteMessage.max,
                    'prefix': 'reward_write_message_',
                    'hasAnchor': true,
                  }
                }}}),_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteMessage,
                    'key': 'max',
                    'min': _vm.xp_action_reward.RewardWriteMessage.min,
                    'prefix': 'reward_write_message_',
                    'hasAnchor': true,
                  }
                }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('reward_write_reply_message_title'),"mini-help-message":_vm.$t('reward_write_reply_message_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteReplyMessage,
                    'key': 'min',
                    'max': _vm.xp_action_reward.RewardWriteReplyMessage.max,
                    'prefix': 'reward_write_reply_message_',
                    'hasAnchor': true,
                  }
                }}}),_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteReplyMessage,
                    'key': 'max',
                    'min': _vm.xp_action_reward.RewardWriteReplyMessage.min,
                    'prefix': 'reward_write_reply_message_',
                    'hasAnchor': true,
                  }
                }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('reward_write_first_message_title'),"mini-help-message":_vm.$t('reward_write_first_message_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteFirstMessage,
                    'key': 'min',
                    'max': _vm.xp_action_reward.RewardWriteFirstMessage.max,
                    'prefix': 'reward_write_first_message_',
                    'hasAnchor': true,
                  }
                }}}),_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardWriteFirstMessage,
                    'key': 'max',
                    'min': _vm.xp_action_reward.RewardWriteFirstMessage.min,
                    'prefix': 'reward_write_first_message_',
                    'hasAnchor': true,
                  }
                }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('reward_start_dialog_title'),"mini-help-message":_vm.$t('reward_start_dialog_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardStartDialog,
                    'key': 'min',
                    'max': _vm.xp_action_reward.RewardStartDialog.max,
                    'prefix': 'reward_start_dialog_',
                    'hasAnchor': true,
                  }
                }}}),_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardStartDialog,
                    'key': 'max',
                    'min': _vm.xp_action_reward.RewardStartDialog.min,
                    'prefix': 'reward_start_dialog_',
                    'hasAnchor': true,
                  }
                }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('reward_mention_title'),"mini-help-message":_vm.$t('reward_mention_mini_help_message')}},[_c('nested-content',[_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardMention,
                    'key': 'min',
                    'max': _vm.xp_action_reward.RewardMention.max,
                    'prefix': 'reward_mention_',
                    'hasAnchor': true,
                  }
                }}}),_c('number-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.xp_action_reward.RewardMention,
                    'key': 'max',
                    'min': _vm.xp_action_reward.RewardMention.min,
                    'prefix': 'reward_mention_',
                    'hasAnchor': true,
                  }
                }}})],1)],1)],1)],1),_c('config-field',{staticClass:"mt-5 mb-5 ml-2 mr-2",attrs:{"title":_vm.$t('xp_bonus_title'),"mini-help-message":_vm.$t('xp_bonus_mini_help_message')}},[_c('nested-content',[_c('time-unit-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'xp_bonus_interval',
                'time-units': [ _vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
                'hasAnchor': true,
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'xp_bonus_message_count',
                'hasAnchor': true,
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'xp_bonus_multiply',
                'hasAnchor': true,
              }
            }}}),_c('time-unit-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'xp_bonus_time',
                'time-units': [ _vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
                'hasAnchor': true,
              }
            }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'start_bonus_xp_message',
                'placeholders': _vm.XP_PLACEHOLDERS,
                'targetToUpload': function () { return _vm.groupUploadTarget; },
                'buttonsHelpView': _vm.EditorButtonsHelpView,
                'editorMode': _vm.MediaEditorMode.TelegramShort,
                'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
                'availableButtonsTypes': _vm.defaultMessageEditorButtons,
                'base-api-url': 'api.chatkeeper.app/cabinet',
                'hasAnchor': true,
              },
            }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'end_bonus_xp_message',
                'placeholders': _vm.XP_PLACEHOLDERS,
                'targetToUpload': function () { return _vm.groupUploadTarget; },
                'buttonsHelpView': _vm.EditorButtonsHelpView,
                'editorMode': _vm.MediaEditorMode.TelegramShort,
                'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
                'availableButtonsTypes': _vm.defaultMessageEditorButtons,
                'base-api-url': 'api.chatkeeper.app/cabinet',
                'hasAnchor': true,
              },
            }}})],1)],1)],1):_vm._e()],1)],1):_c('a-card',{staticClass:"relative w-full"},[_c('not-available-options-overlay',{staticClass:"relative",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_xp_en.m4v' :
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_xp_ru.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.getTagsByFieldKey('extended_reputation')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }