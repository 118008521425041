





































































































































































































































































































































































import Placeholders from "@/mixins/placeholders/placeholders";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue"
import TariffsTagsHelper from '../../mixins/TariffsTagsHelper'
import { InputSetups } from '../../mixins/input-setups'

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  'components': {
    ConfigField,
    NotAvailableOptionsOverlay,
  },

  'data': () => ({
    StepUnit,
    MediaEditorMode,
    EditorButtonsHelpView
  })
})
export default class Xp extends Mixins(UseFields, TariffsTagsHelper, InputSetups, Placeholders) {
  get enabled(): boolean {
    return this.$store.state.chatState.chat.config.xp_action_reward && Object.keys(this.$store.state.chatState.chat.config.xp_action_reward).length > 0
  }

  set enabled(value: boolean) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.xp_action_reward = value ? {
          RewardWriteMessage     : {
            min: 10,
            max: 18,
          },
          RewardWriteReplyMessage: {
            min: 12,
            max: 24,
          },
          RewardWriteFirstMessage: {
            min: 25,
            max: 50,
          },
          RewardStartDialog      : {
            min: 20,
            max: 60,
          },
          RewardMention          : {
            min: 12,
            max: 20,
          },
        } : {}
      }
    })
  }

  get xp_action_reward(): any {
    return this.$store.state.chatState.chat.config.xp_action_reward
  }

  get xp_action_jack_pot_chance_computed(): number {
    return this.$store.state.chatState.chat.config.xp_action_jack_pot_chance / 10;
  }

  set xp_action_jack_pot_chance_computed(value: number) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.xp_action_jack_pot_chance = parseFloat(value.toFixed(1)) * 10
      }
    })
  }

  @Watch('enabled')
  onEnabledChange(value: boolean): void {
    this.$nextTick(() => {
      this.$store.commit('pi/EXEC', {
        'fn': () => {
          this.$store.state.chatState.chat.config.xp_action_reward = value ? {
            RewardWriteMessage     : {
              min: 10,
              max: 18,
            },
            RewardWriteReplyMessage: {
              min: 12,
              max: 24,
            },
            RewardWriteFirstMessage: {
              min: 25,
              max: 50,
            },
            RewardStartDialog      : {
              min: 20,
              max: 60,
            },
            RewardMention          : {
              min: 12,
              max: 20,
            },
          } : {}
        }
      })
    })
  }
}
